












































import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class ChatLoader extends Vue {

}
